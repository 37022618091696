import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Subject, BehaviorSubject } from 'rxjs';
import { UserInterface } from '../interfaces/userInterface';
import { GroupFixedEnum } from '../interfaces/groupFixedEnum';

@Injectable({
  providedIn: 'root'
})
export class LoggedUser {

  TOKEN_KEY = 'accessToken';
  userChange$ = new BehaviorSubject<UserInterface>({});
  loggedUserForChat$ = new BehaviorSubject<UserInterface>({});
  loggedUserForChatReturned = this.loggedUserForChat$.asObservable()
  modules$ = new BehaviorSubject<any>(null);

  constructor(
    private jwtHelper: JwtHelperService
  ) {
    let user = this.getUser();

    user && this.userChange$.next(user.user);
    user && this.loggedUserForChat$.next(user.user)
  }

  getToken(): string {
    return localStorage.getItem(this.TOKEN_KEY);
  }

  getDecodedToken() {
    let token = this.getToken();
    return this.jwtHelper.decodeToken(token);
  }

  saveToken(access_token): boolean {
    if (this.checkToken(access_token)) {
      localStorage.setItem(this.TOKEN_KEY, access_token);
      return true;
    }
    return false;
  }

  validateModule(module) {
    return this.getClientsModules().split(',').filter(m => m == module).length > 0
  }

  getModules() {
    let token = this.getToken();
    if (token) {
      let modules = this.jwtHelper.decodeToken(token).modules;
      let hasModules = modules ? modules : ''
      this.modules$.next([hasModules])
      return hasModules
    } else {
      return ''
    }
  }

  modules() {
    return this.modules$.asObservable()
  }

  clientHasModule(module) {
    let modules: string = this.getClientsModules();
    return modules.includes(module);
  }

  getClientsModules() {
    const token = this.getToken();
    // return this.jwtHelper.decodeToken(token).modules;
    if (token) {
      let modules = this.jwtHelper.decodeToken(token).modules
      return modules ? modules : ''
    } else {
      return ''
    }
  }

  updateUser(user?: any) {
    this.userChange$.next(user);
  }

  addFixedGroup(group: GroupFixedEnum) {
    let user = this.userChange$.getValue();
    this.userChange$.next({ ...user, roles: [...user.roles, group] })
  }

  removeFixedGroup(group: GroupFixedEnum) {
    let user = this.userChange$.getValue();

    this.userChange$.next({ ...user, roles: user.roles.filter(role => role != group) });
  }

  subscribeUserChange$() {
    return this.userChange$.asObservable();
  }

  getUserId(): string {
    const token = this.getToken();
    if (!token) return;

    return this.jwtHelper.decodeToken(token).user.id
  }

  getUserPhoto() {
    const token = this.getToken();
    let decoded = this.jwtHelper.decodeToken(token)

    if (decoded && decoded.user) {
      return decoded.user.photo
    } else {
      return null
    }
  }

  getUser() {
    const token = this.getToken();
    return this.jwtHelper.decodeToken(token)
  }

  getClientId(): string {
    const token = this.getToken();
    return this.jwtHelper.decodeToken(token).user &&
      this.jwtHelper.decodeToken(token).user.clientId ? this.jwtHelper.decodeToken(token).user.clientId : null;
  }

  deleteToken(): boolean {
    if (this.getToken()) {
      localStorage.setItem(this.TOKEN_KEY, '');
      return true;
    }
    return false;
  }

  isAuthenticated(): boolean {
    return this.checkToken(this.getToken());
  }

  getTokenExpirationDate() {
    const token = this.getToken();
    return token ? this.jwtHelper.getTokenExpirationDate(this.getToken()) : null;
  }

  getTokenIssueAtDate() {
    const token = this.getToken();
    return token ? this.jwtHelper.decodeToken(token).iat : null;
  }

  checkRole(expectedRole: string): boolean {
    // return this.jwtHelper.decodeToken(this.getToken()).user.roles.indexOf(expectedRole) != -1;
    if (this.getToken()) {
      let expctRole = this.jwtHelper.decodeToken(this.getToken()).user &&
        this.jwtHelper.decodeToken(this.getToken()).user.roles &&
        this.jwtHelper.decodeToken(this.getToken()).user.roles.indexOf(expectedRole) != -1 ? true : false;
      return expctRole
    } else {
      return false
    }
  }

  getRole(): any[] {
    return this.jwtHelper.decodeToken(this.getToken()).user.roles;
  }

  getGroups(): string[] {
    return this.jwtHelper.decodeToken(this.getToken()).user.groups
  }

  getEmail() {
    const token = this.getToken();
    return token ? this.jwtHelper.decodeToken(token).user.email : null;
  }

  isUserAdmin() {
    const token = this.getToken();
    return token ? this.jwtHelper.decodeToken(token).user.admin : null;
  }

  private checkToken(access_token): boolean {
    let valid = false;
    try {
      valid = !this.jwtHelper.isTokenExpired(access_token);
    } catch (e) {
      return false;
    }
    return valid;
  }
}
