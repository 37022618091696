import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AppConfig } from "../../app.config";
import { CustomAlert, CustomAlertPageable } from "../../interfaces/customAlert";
import { LoggedUser } from "../../models/loggedUser.service";
import { catchError, switchMap, window } from "rxjs/operators";
import { of, BehaviorSubject, Subject } from "rxjs";
import { platformConfigSearch, ActiveDirectoryConfig, Item, OfficeConfig } from "../../interfaces/platformConfig";
import { LgpdDialogComponent } from "../view/lgpd-dialog/lgpd-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRouteSnapshot, Router } from "@angular/router";
import { AliceKeyValueConfig } from "../alice/alice.interface";


@Injectable({ providedIn: 'root' })
export class PlatformConfigService {

  apiVersion = `v${AppConfig.data['apiVersion']}`;
  service = AppConfig.data['services']['cms'];
  serviceAuth = AppConfig.data['services']['auth'];
  serviceUploadMedia = AppConfig.data['services']['uploadMedia'];
  endpoint = `${this.service}/${this.apiVersion}`
  endpointAuth = `${this.serviceAuth}/${this.apiVersion}`
  endpointUploadMedia = `${this.serviceUploadMedia}/${this.apiVersion}`
  endpointDownload = `${AppConfig.data['services']['gateway']}/download/cms/${this.apiVersion}`

  //websocket
  private lgpdTermUpdate$ = new Subject<any>()
  private endpointWebsocket = AppConfig.data['endpointNotifyWebsocket'];
  private ws: WebSocket

  constructor(
    private http: HttpClient,
    private user: LoggedUser,
    private dialog: MatDialog,
  ) { }

  getConfig(configType: 'page' | 'search') {
    return this.http.get<any>(`${this.endpoint}/conf/${configType}`)
  }

  getSearchConfig() {
    return this.http.get<platformConfigSearch>(`${this.endpoint}/custom/search/`).pipe(
      catchError(() => of({
        "stopWords": "de, a, o, que, e, do, da, em, um, para, é, com, não, uma, os, no, se, na, por, mais, as, dos, como, mas, foi, ao, ele, das, tem, à, seu, sua, ou, ser, quando, muito, há, nos, já, está, eu, também, só, pelo, pela, até, isso, ela, entre, era, depois, sem, mesmo, aos, ter, seus, quem, nas, me, esse, eles, estão, você, tinha, foram, essa, num, nem, suas, meu, às, minha, têm, numa, pelos, elas, havia, seja, qual, será, nós, tenho, lhe, deles, essas, esses, pelas, este, fosse, dele, tu, te, vocês, vos, lhes, meus, minhas, teu, tua, teus, tuas, nosso, nossa, nossos, nossas, dela, delas, esta, estes, estas, aquele, aquela, aqueles, aquelas, isto, aquilo, estou, está, estamos, estão, estive, esteve, estivemos, estiveram, estava, estávamos, estavam, estivera, estivéramos, esteja, estejamos, estejam, estivesse, estivéssemos, estivessem, estiver, estivermos, estiverem, hei, há, havemos, hão, houve, houvemos, houveram, houvera, houvéramos, haja, hajamos, hajam, houvesse, houvéssemos, houvessem, houver, houvermos, houverem, houverei, houverá, houveremos, houverão, houveria, houveríamos, houveriam, sou, somos, são, era, éramos, eram, fui, foi, fomos, foram, fora, fôramos, seja, sejamos, sejam, fosse, fôssemos, fossem, for, formos, forem, serei, será, seremos, serão, seria, seríamos, seriam, tenho, tem, temos, tém, tinha, tínhamos, tinham, tive, teve, tivemos, tiveram, tivera, tivéramos, tenha, tenhamos, tenham, tivesse, tivéssemos, tivessem, tiver, tivermos, tiverem, terei, terá, teremos, terão, teria, teríamos, teriam",
        "approach": 3,
        'fuzziness': "AUTO",
        "id": null,
        "customPagePriority": {
          "pageTitle": 10,
          "pageDescription": 5,
          "pageFeatureTextFormatted": 3,
          "pageFeatureTextFixed": 1,
          "pageFeatureMediaDisplay": 3,
          "pageUserPublish": 1,
          "pageKeywords": 1
        },
        "customMediaPriority": {
          "mediaTitle": 10,
          "mediaDescription": 5,
          "mediaFileContent": 3,
          "mediaUserPublish": 1
        },
        "searchMedia": false,
        "searchPage": true,
        "searchUser": false
      } as platformConfigSearch))
    )
  }

  saveSearchConfig(config: platformConfigSearch, action: 'create' | 'edit') {
    if (action == 'create') {
      return this.http.post<platformConfigSearch>(`${this.endpoint}/custom/search/create`, config)
    } else {
      return this.http.put<platformConfigSearch>(`${this.endpoint}/custom/search/change/${config.id}`, config)
    }
  }

  saveConfig(conf: any, configType: 'page' | 'search') {
    return this.http.put(`${this.endpoint}/conf/${configType}`, conf)
  }
  clearCache() {
    return this.http.get(`${this.endpoint}/cache/clear`)
  }

  clearIndex() {
    return this.http.get(`${AppConfig.data['services']['user']}/${this.apiVersion}/users/reindex`)
  }

  getAlertsList() {
    return this.http.get<CustomAlert[]>(`${this.endpoint}/customAlerts`)
  }

  getAlertsListPageable(page: number = 0, size: number = 20) {
    return this.http.get<CustomAlertPageable>(`${this.endpoint}/customAlerts/pageable?page=${page}&size=${size}`)
  }

  saveAlert(alert: CustomAlert) {
    if (alert.id) {
      return this.http.put(`${this.endpoint}/customAlerts/${alert.id}`, alert)
    } else {
      return this.http.post(`${this.endpoint}/customAlerts/create/customAlert`, alert);
    }
  }

  deleteAlert(alert: CustomAlert) {
    return this.http.delete(`${this.endpoint}/customAlerts/${alert.id}`);
  }

  getAuthPwdConfig() {
    return this.http.get<any>(`${AppConfig.data['services']['auth']}/${this.apiVersion}/auth_config/`)
  }

  saveAuthPwdConfig(config) {

    return this.http.put(`${AppConfig.data['services']['auth']}/${this.apiVersion}/auth_config/save`, config)
  }

  getAdConfig() {
    return this.http.get<ActiveDirectoryConfig>(`${this.endpointAuth}/auth_config/ad`)
  }

  validateAdConfig(config: Item) {
    return this.http.post(`${this.endpointAuth}/auth_config/ad/valid`, config)
  }

  validateOfficeConfig(config?: OfficeConfig) {
    return this.http.post(`${this.endpointAuth}/oauth/ms/test/`, config, { headers: { ignoreInterceptorRedirect: 'true' } }).pipe(
      /* switchMap((code: any) => this.validateOfficeConnect({...config, clientId, authCode: code.authCode, state: code.state})) */
    )
    /* return `${this.endpointAuth}/oauth/ms/test` */
  }

  validateOfficeConnect(config) {
    return this.http.post(`${this.endpointAuth}/oauth/ms/connect`, config, { headers: { ignoreInterceptorRedirect: 'true' } })
  }

  validateAdsfConfig(config?: any) {
    return this.http.post(`${this.endpointAuth}/oauth/adfs/test`, config, { headers: { ignoreInterceptorRedirect: 'true' } })

  }

  saveAdConfig(config: ActiveDirectoryConfig) {
    return this.http.put(`${this.endpointAuth}/auth_config/ad`, config)
  }
  saveOffice365(config: OfficeConfig) {
    return this.http.put(`${this.endpointAuth}/auth_config/ms`, config)
  }
  getOfficeConfig() {
    return this.http.get(`${this.endpointAuth}/auth_config/ms`);
  }

  getSSOConfig() {
    return this.http.get<any>(`${this.endpointAuth}/auth_config/sso`)
  }

  saveSSOConfig(config) {
    return this.http.put(`${this.endpointAuth}/auth_config/sso`, config)
  }

  getGoogleConfig(clientID) {
    return this.http.get<any>(`${this.endpointAuth}/auth_config/google/${clientID}`)
  }

  saveGoogleAuthConfig(config: any) {
    return this.http.put(`${this.endpointAuth}/auth_config/google`, config)
  }

  getAdfsConfig() {
    return this.http.get<any>(`${this.endpointAuth}/auth_config/adfs`)
  }

  saveAdfsConfig(data) {
    return this.http.put<any>(`${this.endpointAuth}/auth_config/adfs`, data)
  }

  saveLGPDTerm(term: any) {
    return this.http.put(`${this.endpoint}/lgpd/save`, term);
  }

  getLGPDTerm() {
    return this.http.get(`${this.endpoint}/lgpd`);
  }

  getLastTermAccepted() {
    return this.http.get(`${this.endpoint}/lgpd/last/term/accepted`);
  }

  getLGPDstatistic() {
    return this.http.put(`${this.endpoint}/lgpd/statistic`, {})
  }

  getLGPDstatisticUnpaged() {
    return this.http.get(`${this.endpoint}/lgpd/statistic/unpaged`)
  }

  downloadLGPDReportFile(id: string) {
    return this.http.get(`${this.endpointDownload}/lgpd/report/${id}`, { observe: 'response', responseType: 'blob' })
  }

  getTermForAcceptance() {
    return this.http.get(`${this.endpoint}/lgpd/term/accept/reading`)
  }

  confirmReadTermAcceptance() {
    return this.http.put(`${this.endpoint}/lgpd/term/accept`, {});
  }

  disconnectWebsocket() {
    this.ws ? this.ws.close() : false;
  }

  saveOktaConfig(data) {
    return this.http.put<any>(`${this.endpointAuth}/auth_config/okta`, data)
  };

  getOktaConfig() {
    return this.http.get<any>(`${this.endpointAuth}/auth_config/okta`)
  };

  getAndShowTerm() {
    const showModal = (term) => {
      const route = document.URL;

      if (route.includes('hp') || route.includes('hp/page')) {
        let dialog$ = this.dialog.open(LgpdDialogComponent, { data: { term }, maxWidth: '90vw', maxHeight: '80vw', disableClose: true, minWidth: '45vw' })

        dialog$.afterClosed().subscribe(accepted => {
          if (accepted) {
            this.confirmReadTermAcceptance().subscribe(() => { })
          }
        })
      }
    }

    this.getTermForAcceptance().subscribe({
      next: (term: any) => term && !term.error && showModal(term)
    })
  }

  recreatePageIndex() {
    return this.http.post(`${this.endpoint}/custom/searchIndex/reindex/page`, {})
  }

  recreateMediaIndex() {
    const clientId = this.user.getClientId();

    return this.http.post(`${this.endpointUploadMedia}/indexer/reindex/media`, { clientId })
  }

  getFixedMenu() {
    return this.http.get<any>(`${this.endpoint}/conf/fixedMenu`)
  }


  getCanDownloadPdf() {
    return this.http.get<any>(`${this.endpoint}/conf/canDownloadPdf`)
  }

  getBlockCopy() {
    return this.http.get<any>(`${this.endpoint}/conf/blockCopy`, { headers: { ignoreInterceptorRedirect: 'true' } })
  }

  getShowHomepagesOnNavbar() {
    return this.http.get<any>(`${this.endpoint}/conf/showHomepagesOnNavbar`)
  }

  getPrincipalHomepage() {
    return this.http.get<any>(`${this.endpoint}/conf/principalHomepage`)
  }

  getEnableSearchAllHomepages() {
    return this.http.get<any>(`${this.endpoint}/custom/search/searchAllHomepages`)
  }
}
