import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map, catchError } from "rxjs/operators";
import { Observable, BehaviorSubject, of } from "rxjs";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";

import { AppConfig } from "../../../app.config";
import { HomepageInterface, BannerInterface, Widget } from "../../../interfaces/homepage";
import { ContentMenu } from '../../../interfaces/contentMenu'
import { Homepage, HomepageData, HomepageColorPayload, SaveWidgetConfig, BannerInterfaceManager } from "../../../models/homepage";
import * as HomepageDefinitionActions from "../actions/homepageDefinition.actions"
import * as PendentActions from "../../../actions/pendentItems.actions"
import { PendentItem } from "../../../models/pendentItem";
import { PendentItemsState } from "../../../stores/pendentItems.state";
import { HomepageDefinitionState } from "../cms.state";
import { OrderParamEnum, PageComment } from "../../../models/page";

@Injectable({ providedIn: 'root' })
export class ContentService {

  apiVersion = `v${AppConfig.data['apiVersion']}`;
  service = AppConfig.data['services']['cms'];
  endpoint = `${this.service}/${this.apiVersion}`
  homepageDefinition$: BehaviorSubject<Homepage>
  homepageDefinition: Homepage
  homepageId: string;
  pendent$: BehaviorSubject<PendentItem> = new BehaviorSubject({})
  contentMenulist: ContentMenu[];

  constructor(
    private http: HttpClient,
    private store: Store<HomepageDefinitionState>,
    private pendentStore: Store<PendentItemsState>,
    private router: Router
  ) {
    this.getStoreData()
    this.getHomepageUpdate()
    this.getPendentItems()

  }

  private _mapRequest(action: HomepageDefinitionActions.Actions, data) {
    this.homepageDefinition$.next({ ...this.homepageDefinition, ...data });
    this.homepageId = data.id
    this.store.dispatch(action)
    this.getHomepageObj()
  }

  private getPendentItems() {
    this.pendentStore.select('pendentItems').subscribe(pendent => this.pendent$.next(pendent))
  }

  private getStoreData() {
    this.store.select('homepageDefinition')
      .pipe()
      .subscribe((resp: Homepage) => {
        if (resp) {
          this.homepageDefinition$ = new BehaviorSubject(resp)
          this.homepageDefinition = resp
          this.homepageId = resp.id
        } else {
          this.homepageDefinition$ = new BehaviorSubject({} as Homepage)
        }
      })
  }

  getHomepageIdByUrl(url: string) {
    return this.http.post(`${this.endpoint}/homepages/url/info`, { url }, { headers: { ignoreInterceptorRedirect: 'true' } })
  }

  hasLoginBackgroundInFirstHome(cId) {
    let headers = new HttpHeaders().append('accept', 'application/json').append('content-type', 'application/json').append('ignoreInterceptorRedirect', 'true');
    return this.http.get(`${this.endpoint}/homepages/background/${cId}`, { headers })
  }

  storeDispatch(action: HomepageDefinitionActions.Actions) {
    return this.store.dispatch(action)
  }

  private getHomepageUpdate() {
    this.homepageDefinition$.subscribe(resp => {
      this.homepageDefinition = resp
    })
  }

  setHomepageData(data: HomepageData) {

    if (data.id) {
      return this.http.post<Homepage>(`${this.endpoint}/homepages/save/data`, data)
        .pipe(
          map(homepage => {
            let { title, id, language, completedData, clientId } = homepage
            this._mapRequest(new HomepageDefinitionActions.SetHomepageData({ id, language, title, completedData, clientId }), homepage)
            return homepage
          })
        )
    } else {
      return this.http.post<Homepage>(`${this.endpoint}/homepages/save/data`, data)
        .pipe(
          map((resp) => {
            let { id, title, language, completedData, clientId } = resp
            this._mapRequest(new HomepageDefinitionActions.SetHomepageData({ id, title, language, completedData, clientId }), resp)
            return resp
          })
        )
    }
  }

  movePage_Old(pageId: string, destinyId: string, destinyType: 'HOMEPAGE' | 'PAGE') {
    return this.http.post(`${this.endpoint}/page/move/${pageId}/to/${destinyType.toLowerCase()}/${destinyId}`, {})
  }

  movePage(pageId: string, destinyId: string, destinyType: 'HOMEPAGE' | 'PAGE') {
    return this.http.post(`${this.endpoint}/page/move_from_menu/${pageId}/to/${destinyType.toLowerCase()}/${destinyId}`, {})
  }

  copyHomepage(homepageId: string, title: string) {
    return this.http.post(`${this.endpoint}/homepage/copy/${homepageId}`, { title })
  }

  copyPage(pageId: string, destinyId: string, destinyType: 'HOMEPAGE' | 'PAGE') {
    return this.http.post(`${this.endpoint}/pages/copy/${pageId}/to/${destinyType == 'HOMEPAGE' ? 'homepage' : 'subpage'}/${destinyId}`, {})
  }

  importPage(id: string, destinyId: string, destinyType: 'HOMEPAGE' | 'PAGE') {
    return this.http.post(`${this.endpoint}/page/import_from_menu/${id}/to/${destinyType == 'HOMEPAGE' ? 'homepage' : 'page'}/${destinyId}`, {})
  }

  getHomepageDefinitionStatus() {
    return this.homepageDefinition$
  }

  getHomepageObj() {
    return this.homepageDefinition$.asObservable()
  }

  cancelHomepageDefinitino() {
    this.store.dispatch(new HomepageDefinitionActions.ResetForm())
  }

  deleteHomepage(id: string) {
    return this.http.delete(`${this.endpoint}/homepages/${id}`)
  }

  resetPendentHomepage() {
    this.pendentStore.dispatch(new PendentActions.ResetDefineHomepage())
  }

  getAccessibleHomepages() {
    return this.http.get(`${this.endpoint}/homepages/show/accessible`)
  }

  validadePendentHome(home: Homepage) {
    let { completedColors, completedData, completedSetUpBanner, completedSetUpLogo, completedWidgets, completedWidgetsConfig } = home

    if (!completedData) {
      this.router.navigate(['/cms', 'content', 'homepage-data', home.id])
    } else if (!completedSetUpLogo) {
      this.router.navigate(['/cms', 'content', 'homepage-define-logo', home.id])
    } else if (!completedColors) {
      this.router.navigate(['/cms', 'content', 'color-definition', home.id])
    } else if (!completedSetUpBanner || !completedWidgets || !completedWidgetsConfig) {
      this.router.navigate(['/cms', 'content', 'create-homepage', home.id])
    }
  }

  getHomepageById(id) {
    return this.http.get<Homepage>(`${this.endpoint}/homepages/${id}`)
      .pipe(
        map((resp: Homepage) => {
          // added completets as true to resp so dont registes as pendent items and show on login
          this._mapRequest(new HomepageDefinitionActions.SetFormEdit({ ...resp, completedColors: true, completedData: true, completedSetUpBanner: true, completedSetUpLogo: true, completedWidgets: true, completedWidgetsConfig: true }), resp)
          return resp;
        })
      )

  }

  getHomepageFewInfo(id) {
    return this.http.get<Homepage>(`${this.endpoint}/homepages/${id}/mini`)
  }

  getHomepageByIdEdit(id) {
    return this.http.get<Homepage>(`${this.endpoint}/homepages/${id}/edit`)
      .pipe(
        map((resp: Homepage) => {
          this._mapRequest(new HomepageDefinitionActions.SetFormEdit(resp), resp)
          return resp;
        })
      )

  }

  setHomepageColors(id: string, colorsData: HomepageColorPayload) {
    let { colors } = colorsData
    return this.http.put<HomepageInterface>(`${this.endpoint}/homepages/${id}/save/colors`, { colors })
      .pipe(
        map(resp => {
          this._mapRequest(new HomepageDefinitionActions.SetHomepageColors(colorsData), resp)
          return resp;
        })
      )
  }

  setHomepageLogo(homepageId: string, data: {}) {
    return this.http.put<Homepage>(`${this.endpoint}/homepages/${homepageId}/save/logo_fav`, data)
      .pipe(
        map(resp => {
          this.homepageDefinition$.next(resp)
          this.homepageId = resp.id
          return resp;
        })
      )
  }

  setHomepageBanner(id: string, data: { banner: BannerInterface }): any {
    return this.http.put<Homepage>(`${this.endpoint}/homepages/${id}/save/banner`, data)
      .pipe(
        map(resp => {
          let { banner, completedSetUpBanner } = resp
          this._mapRequest(new HomepageDefinitionActions.SetHomepageBanner({ banner, completedSetUpBanner }), resp)
          return resp;
        })
      )
  }

  setHomepageWidgets(id: string, data: Widget) {
    return this.http.put<Homepage>(`${this.endpoint}/homepages/${id}/save/widgets`, data)
      .pipe(
        map(resp => {
          this._mapRequest(new HomepageDefinitionActions.SetHomepageWidget(resp), resp)
          return resp;
        })
      )
  }

  hasHomepage() {
    return this.http.get<any>(`${this.endpoint}/homepages/has_homepage`)
      .pipe(
        map(resp => {
          return {
            ...resp, completedColors: this.homepageDefinition.completedColors,
            completedData: this.homepageDefinition.completedData,
            completedSetUpBanner: this.homepageDefinition.completedSetUpBanner,
            completedWidgets: this.homepageDefinition.completedWidgets,
            completedSetUpLogo: this.homepageDefinition.completedSetUpLogo,
            id: this.homepageDefinition.id,
            pendentHomepage: this.pendent$.value.defineHomepage
          }
        })
      )
  }

  getHomepageStats() {
    return this.homepageDefinition
  }

  saveWidgetsConfig(data: SaveWidgetConfig, id: string) {
    return this.http.put(`${this.endpoint}/homepages/${id}/save/widgets/config`, data)
  }

  ContentMenuList() {
    return this.http.get<ContentMenu[]>(`${this.endpoint}/menu/management/`)
      .pipe(
        catchError(err => of([] as ContentMenu[])),
        map(homes => {
          this.contentMenulist = homes
          return homes
        })
      )
  }

  contentMenuListByStepMain() {
    return this.http.get<ContentMenu[]>(`${this.endpoint}/menu/management/main`)
      .pipe(
        catchError(err => of([] as ContentMenu[])),
        map(homes => {
          this.contentMenulist = homes
          return homes
        })
      )
  }

  contentMenuListByStepSubmenu(id) {
    return this.http.get<any>(`${this.endpoint}/menu/management/submenu/${id}`);
  }

  contentMenuListChildren(id) {
    return this.http.get<ContentMenu[]>(`${this.endpoint}/menu/management/itens_menu/${id}`);
  };

  contentMenuListChildrenSubpages(id) {
    return this.http.get<ContentMenu[]>(`${this.endpoint}/menu/management/itens_submenu/${id}`);
  };

  contentMenuSearch(title: string, show_published: boolean = true, show_expired: boolean = false, show_scheduled: boolean = false, show_no_publish_date: boolean = false, id: string) {
    return this.http.post<ContentMenu[]>(`${this.endpoint}/menu/management/search/`, { title, show_published, show_expired, show_scheduled, show_no_publish_date, id });
  }

  recreateMenuList() {
    return this.http.post(`${this.endpoint}/menu/management/`, {});
  }

  // Banner
  createBanner(data: BannerInterfaceManager, homepageId: string) {
    return this.http.post<any>(`${this.endpoint}/bannermanagements/create/${homepageId}`, data)
  }

  getAllBanners(homepageId: string): Observable<any[]> {
    return this.http.get<any>(`${this.endpoint}/bannermanagements/show/all/${homepageId}`)
  }

  deleteBanner(id: string) {
    return this.http.delete<any>(`${this.endpoint}/bannermanagements/${id}`)
  }

  getBannerById(bannerId: string) {
    return this.http.get<any>(`${this.endpoint}/bannermanagements/${bannerId}/view`)
  }

  bennerUpdate(data: BannerInterfaceManager, bannerId: string, homepageId: string) {
    return this.http.put<BannerInterfaceManager>(`${this.endpoint}/bannermanagements/${bannerId}/${homepageId}`, data)
  }

  saveBannerList(updatedList: any, homepageId: string) {
    return this.http.put<any>(`${this.endpoint}/bannermanagements/save/all/${homepageId}`, updatedList);
  }

  segregateArea(id: string, menuId: string, data) {
    if (menuId) {
      return this.http.put<any>(`${this.endpoint}/pages/${menuId}/${id}/segregate/acl`, data)
    } else {
      return this.http.put<any>(`${this.endpoint}/pages/${id}/segregate/acl`, data)
    }
  }
  segregateHomepageArea(id: string, data) {
    return this.http.put<any>(`${this.endpoint}/homepages/${id}/segregate/acl`, data)
  }

  removeSegregatePage(id: string, menuId: string) {
    if (menuId) {
      return this.http.put<any>(`${this.endpoint}/pages/${menuId}/${id}/remove/segregate/acl`, {})
    } else {
      return this.http.put<any>(`${this.endpoint}/pages/${id}/remove/segregate/acl`, {})
    }
  }

  removeSegregateHomepage(id: string) {
    return this.http.put<any>(`${this.endpoint}/homepages/${id}/remove/segregate/acl`, {})
  }

  editAcl(aclId, list) {
    return this.http.put<any>(`${this.endpoint}/acls/${aclId}`, list)
  }

  sendPageFeedback(config: any, pageId: any) {
    return this.http.post(`${this.endpoint}/pages/feedback/${pageId}`, config);
  }

  getPageFeedbackList(pageId: string, homePageId: string, category: string, startDate?: string, endDate?: string, orderByParam?: OrderParamEnum, page?: any, size?: any) {
    return this.http.post(`${this.endpoint}/pages/feedback/list`, { pageId, homePageId, category, startDate, endDate, orderByParam }, { params: { page, size } })
  }

  getCommentsList(pageId, homePageId, status?, page?, size?, removePagination?) {
    if (removePagination) {
      return this.http.post<PageComment[]>(`${this.endpoint}/pages/comment/list`, { pageId, homePageId, status })
    } else {
      return this.http.post<PageComment[]>(`${this.endpoint}/pages/comment/list`, { pageId, homePageId, status }, { params: { page, size } })
    }
  }

  approveComment(pageId, commentId) {
    return this.http.post(`${this.endpoint}/pages/comment/approve/${pageId}/${commentId}`, {})
  }

  disapproveComment(pageId, commentId) {
    return this.http.post(`${this.endpoint}/pages/comment/disapprove/${pageId}/${commentId}`, {})
  }

  approveMultipleComments(pageId, commentId: string[]) {
    return this.http.post(`${this.endpoint}/pages/comment/approve/${pageId}`, commentId)
  }

  disapproveMultipleComments(pageId, commentId: string[]) {
    return this.http.post(`${this.endpoint}/pages/comment/disapprove/${pageId}`, commentId)
  }

  addFieldToFormTemplate(config, templateId: string) {
    return this.http.post(`${this.endpoint}/features/forms/template/field/create/${templateId}`, config)
  }
  addFieldToFormPage(config, pageId, featureId) {
    return this.http.post(`${this.endpoint}/features/forms/page/field/create/${pageId}/${featureId}`, config)
  }

  getSuperiorMenuFirstLevel(id) {
    return this.http.get(`${this.endpoint}/homepages/menu/config/${id}`)
  }

  getSuperiorMenuSubPages(id) {
    return this.http.get(`${this.endpoint}/homepages/menu/config/subPages/${id}`)
  }

  setOrdination(menuManagementId, order) {
    return this.http.post(`${this.endpoint}/homepages/menu/config/update/order/${menuManagementId}`, { pagesOrdination: order })
  }

  reorderFirstLevel(homePageId, ids) {
    return this.http.post(`${this.endpoint}/homepages/menu/config/reorder/${homePageId}`, { ids })
  }

  reorderSubLevels(menuManagementId, ids) {
    return this.http.post(`${this.endpoint}/homepages/menu/config/subPages/reorder/${menuManagementId}`, { ids })
  }

  getTemplateForms() {
    return this.http.get(`${this.endpoint}/features/forms/template`)
  }

  getPageForms() {
    return this.http.get(`${this.endpoint}/features/forms/page`)
  }
  sendManifest(base64) {
    return this.http.put(`${this.endpoint}/conf/manifest`, { base64 })
  }
  getManifest(clientId) {
    return this.http.get(`${this.endpoint}/conf/manifest/${clientId}`)
  }

  addBookmarks(menuId, pageId, homePageId) {
    return this.http.put(`${this.endpoint}/pagefavorites/${menuId}/${pageId}/${homePageId}`, {})
  }

  removeBookmarks(menuId, pageId, homePageId) {
    return this.http.delete(`${this.endpoint}/pagefavorites/${menuId}/${pageId}/${homePageId}`)
  }
}
