import { Injectable, NgModule } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Router } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { LoggedUser } from '../models/loggedUser.service';

@Injectable()
export class HttpsRequestInterceptor implements HttpInterceptor {

  constructor(private user: LoggedUser) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let newReq;
    const authentication = this.user.getToken();
    let url = window.location.href ? window.location.href : '';
    let skipToken: boolean = !!req.headers.get('skipToken');

    req.headers.delete('skipToken');

    const isAsset = req.url.indexOf('assets') == 0 || req.url.indexOf('assets') == 1;

    if (authentication !== undefined && this.user.isAuthenticated() && !skipToken && !isAsset) {
      newReq = req.clone({
        setHeaders: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': `Bearer ${authentication}`,
          'page-url': url
        }
      });

      const customContentType = !!req.headers.get('multi-part-form');

      if (!customContentType) {
        newReq = newReq.clone({
          setHeaders: {
            'Content-Type': 'application/json'
          }
        });
      } else {
        newReq.headers.delete('custom-content-type');
      }

    } else {
      newReq = req.clone();
    };

    return next.handle(newReq);
  }


}


@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(private user: LoggedUser, private router: Router) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // do stuff with response if you want
        }
      }, (err: any) => {
        if (err instanceof HttpErrorResponse) {
          //add ignore intercept redirect
          let ignore: boolean = !!req.headers.get('ignoreInterceptorRedirect');
          if (!ignore) {
            const status = err.status;
            let auth = '/login'
            if (status === 401) {
              let token = this.user.getToken() ? this.user.getDecodedToken() : {};
              let clientid = token.user ? token.user.clientId : undefined;
              this.user.deleteToken();
              let url = this.router.url;
              if (url != '/' && url.indexOf('login') == -1) {
                localStorage.setItem('redirectTo', this.router.url);
                let modeAuth = token.modeAuth;
                localStorage.removeItem('accessToken')
                if (modeAuth == 'ACTIVE_DIRECTORY') {
                  auth = '/login/ad'
                } else if (modeAuth == 'GOOGLE') {
                  auth = '/login/google'
                } else if (modeAuth == 'OFFICE_365') {
                  auth = '/login/office'
                } else if (modeAuth == 'ADFS') {
                  auth = '/login/adfs'
                } else if (modeAuth == 'MANUAL') {
                  auth = '/login/manual'
                } else if (modeAuth == 'OKTA') {
                  auth = '/login/okta'
                }else if (modeAuth =='X509'){
                  auth = '/login/all'
                }
                this.router.navigate([auth], { queryParams: { signedout: true, clientid } });
              }
            } else if (status === 403) {
              // this.router.navigate(['/error', 403]);
            } else if (status === 500 || status === 502) {
              // this.router.navigate(['/error', status], { skipLocationChange: true });
            }
          }
        }
      })
    )
  }
}

@Injectable()
export class NoCacheHeadersInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const authReq = req.clone({
      setHeaders: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache'
      }
    });
    return next.handle(authReq);
  }
}

@NgModule({
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpsRequestInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true }
  ]
})
export class InterceptorModule { }
